import * as React from 'react';
import { Layout } from '../../layout';
import { Seo } from '../../components/seo';
import BGContact from '../../assets/img/bg-contact.jpeg';

const EmailSuccessPage = () => {
  return (
    <Layout>
      <Seo
        title="お問い合わせ"
        description="ご返信までにお時間がかかる場合もございますので、お急ぎの方はお電話にてお問い合わせ下さい。"
      />
      <main>
        <section class="mb-6">
          <div
            style={{ backgroundImage: `url(${BGContact})` }}
            class="h-40vh md:h-60vh relative flex items-center justify-center 2xl:-mt-2 w-screen bg-cover bg-center overflow-hidden"
          >
            <h1 class="flex flex-col items-center justify-center tracking-widest">
              <span class="text-white text-2xl md:text-4xl">CONTACT</span>
              <span class="mt-2 text-white text-xs md:text-base">
                お問い合わせ
              </span>
            </h1>
            <div>
              <svg
                class="wave absolute bottom-0 inset-x-0 -mb-1 w-200vw text-white fill-current"
                viewBox="0 0 1440 64"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M360 19.483C240 -6.49433 120 -6.49433 0 19.483V63.5H1440V19.483C1320 45.4603 1200 45.4603 1080 19.483C960 -6.49433 840 -6.49433 720 19.483C600 45.4603 480 45.4603 360 19.483Z" />
              </svg>
            </div>
          </div>
        </section>
        <section class="mb-6 mx-auto px-6 md:px-10 lg:max-w-5xl">
          <div class="flex items-center justify-end">
            <div class="flex items-center justify-center">
              <span class="text-primary-blue mr-2 text-xs md:text-sm">
                <a href="/">TOP</a>
              </span>
              <span class="mr-2 text-xs md:text-sm">/</span>
              <span class="text-xs md:text-sm">お問い合わせ</span>
            </div>
          </div>
        </section>
        <section class="mb-12 mx-auto px-6 md:mb-24 md:px-10 lg:mb-32 lg:max-w-4xl">
          <div class="flex flex-col items-center justify-center mb-12 lg:mb-20">
            <div class="mb-2 md:mb-4">
              <h2 class="text-primary-blue md:text-xl">お問い合わせ完了</h2>
            </div>
            <div class="bg-primary-blue w-8 h-0.5 md:w-12"></div>
            <div class="mt-4 md:mt-8">
              <p class="text-xs tracking-wider leading-5 md:text-sm md:leading-6">
                この度はお問い合わせメールをお送りいただきありがとうございます。
                <br />
                後ほど、担当者よりご連絡をさせていただきます。
                <br />
                今しばらくお待ちくださいますようよろしくお願い申し上げます。
                <br />
                <br />
                なお、しばらくたっても当社より返信、返答がない場合は、
                <br />
                お客様によりご入力いただいたメールアドレスに誤りがある場合がございます。
                <br />
                その際は、お手数ですが再度送信いただくか、お電話にてご連絡いただけますと幸いです。
              </p>
            </div>
          </div>
          <div class="w-fit-content relative mx-auto">
            <a
              class="btn-background-slide group bg-primary-blue flex items-center justify-center mx-auto py-2 w-48 md:py-4 md:w-64"
              href="/"
            >
              <span class="group-hover:text-primary-blue z-10 text-white text-sm tracking-wider transition duration-300 ease-in-out md:text-base">
                HOMEへ戻る
              </span>
            </a>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default EmailSuccessPage;
